// ** Third Party Components
import styled from "styled-components";

// ** Custom Components
import Heading from "components/typography/Heading";

// #####################################################

export const StyledHeading = styled((props) => <Heading {...props} bold />)`
	font-weight: bold;
	font-size: 1.25rem;
`;

// #####################################################
