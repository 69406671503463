import styled, { css } from "styled-components";
import Heading from "components/typography/Heading";

const Header = styled(Heading)`
	font-weight: bold;
	font-size: 1.25rem;
	margin: 14px 0;
	${({ main }) =>
		main &&
		css`
			margin-top: 0;
		`}
`;

export default Header;
